import api from "../../services/api";

const usePermissions = () => {

  const fetchPermissions = async () => {
    const { data } = await api.get("/permission");
      return data;
  };

  return { fetchPermissions };
};

export default usePermissions;
